<template>
    <div class="modal-row2 modal-height-max">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 0 }" @click="activeTab = 0" id="allotment-tab"
                       data-toggle="tab" href="#allotment" role="tab" aria-controls="allotment"
                       aria-disabled="true">{{ t('ALLOTMENT.PRINCIPAL_DATA') }}</a>
                </li>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                <li class="nav-item" role="presentation">
                    <a class="nav-link required" :class="{ active: activeTab == 1 }"
                       @click.stop.prevent="validateSecondAbaClick"
                       id="localization-tab" data-toggle="tab" href="#localization" role="tab"
                       aria-controls="localization"
                       aria-disabled="true">{{ t('ALLOTMENT.ADDRESS') }} </a>
                </li>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 2 }" @click.stop.prevent="validateLastAbaClick"
                       id="financial-tab" data-toggle="tab" href="#financial" role="tab" aria-controls="financial"
                       aria-disabled="true">{{ t('ALLOTMENT.FINANCIAL') }}</a>
                </li>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                <li class="nav-item" role="presentation">
                    <a class="nav-link" :class="{ active: activeTab == 3 }" @click.stop.prevent="validateThirthAbaClick"
                       id="data-add-tab" data-toggle="tab" href="#data-add" role="tab" aria-controls="data-add"
                       aria-disabled="true">{{ t('ALLOTMENT.DATA_ADD') }}</a>
                </li>
            </div>
        </ul>

        <form @submit.prevent="create(false)">
            <div class="tab-content modal-body" id="myTabContent">
                <div class="tab-pane fade" :class="{ active: activeTab == 0, show: activeTab == 0 }" id="allotment"
                     role="tabpanel" aria-labelledby="allotment-tab">
                    <div class="row gutters">
                        <div class="dimensions mb-2" style="margin-left: 34%;">altura e largura: Max: 380 x 380px - Min:
                            150 x
                            150px
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div class="logo-size logo mb-2"
                                 :style="widthPhone() ? 'margin-left: 35% !important' : 'margin-left: 42% !important'">
                                <img class="user-logo" :src="imageCropped ? imageCropped : allotment.image"/>
                                <div class="status pointer">
                                    <label for="logo"><i class="icon-camera camera2"/></label>
                                    <input type="file" id="logo" data-cy="allotment_creat_add_image"
                                           accept="image/jpeg/*"
                                           @change="uploadImage()" style="display: none"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                                <label for="name" class="required">{{ t('ALLOTMENT.NAME_COMERCIAL') }}: </label>
                                <input data-cy="allotment_creat_modal_name" type="text" id="name"
                                       v-model="allotment.name"
                                       name="name" @keyup="inputName(allotment.name)" class="form-control"
                                       placeholder="Digite o nome">
                                <div class="validation" v-if="isNameInvalid">{{ t(msgError) }}</div>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                                <label for="name">{{ t('ALLOTMENT.NAME_APPROVE') }}: </label>
                                <input type="text" id="name_approve"
                                       v-model="allotment.name_approbation"
                                       name="name_approve" class="form-control"
                                       placeholder="Nome Aprovação">
                            </div>
                        </div>
                    </div>
                    <div class="row gutters">
                        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="maxReserve" class="required">{{
                                        t('ALLOTMENT.MAX_NUMBER_RESERVE_PER_REALTOR')
                                    }}: </label>
                                <input data-cy="allotment_creat_modal_maxRes" type="number" id="maxReserve"
                                       v-model="allotment.max_number_reserve_per_realtor" name="maxReserve"
                                       @keyup="inputGeneric(allotment.max_number_reserve_per_realtor, 'maxReserve')"
                                       class="form-control" placeholder="00">
                                <div class="validation" id="invalidmaxReserve" style="display: none">
                                    {{ t('GENERAL.MSG.REQUIRED_FIELD') }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="form-group">
                                <label for="reserveDuration" class="required">{{
                                        t('ALLOTMENT.MAX_DURATION_RESERVES')
                                    }}: </label>
                                <input class="form-control" type="text" id="reserveDuration" name="reserveDuration"
                                       v-model.lazy="allotment.reserve_duration_max"
                                       v-money3="configDuration" @keyup="inputReserve">
                                <div class="validation" v-if="isReserveDurationInvalid">{{ msgReserveError }}</div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="form-group">
                                <label for="date_launch" class="required">{{
                                        t('ALLOTMENT.DATE_LAUNCH')
                                    }}: </label>
                                <input class="form-control" type="date" id="date_launch" name="date_launch"
                                       v-model="allotment.date_launch">
                                <div class="validation" v-if="invalidFirstAba.date_launch">
                                    {{ invalidFirstAba.date_launch }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6 col-6">
                            <div class="form-group">
                                <label for="public_area_config_id">{{
                                        t('CONFIG_PUBLIC_AREA.PUBLIC_AREA_CONFIG_RES')
                                    }}</label>
                                <select2 id="public_area_config_id" :settings="{width: '100%'}"
                                         :options="publicAreasConfigs"
                                         :disabled="disablePublicArea" v-model="allotment.public_area_config_id"/>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6">
                            <div class="form-group">
                                <label for="decree_aproval" class="required">{{
                                        t('ALLOTMENT.DECREE_APPROVAL')
                                    }}:
                                </label>
                                <input class="form-control" type="text" id="decree_aproval" name="decree_aproval"
                                       placeholder="Decreto de Aprovação"
                                       v-model="allotment.decree_approval">
                                <div class="validation" v-if="invalidFirstAba.decree_approval">
                                    {{ invalidFirstAba.decree_approval }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-7">
                            <div class="form-group">
                                <label for="date_decree_approval" class="required">{{
                                        t('ALLOTMENT.DATE_DECREE_APPROVAL')
                                    }}: </label>
                                <input class="form-control" type="date" id="date_decree_approval"
                                       name="date_decree_approval"
                                       v-model="allotment.date_decree_approval">
                                <div class="validation" v-if="invalidFirstAba.date_decree_approval">
                                    {{ invalidFirstAba.date_decree_approval }}
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-5">
                            <div class="form-group">
                                <label for="total_area" class="required">{{
                                        t('ALLOTMENT.TOTAL_AREA')
                                    }}: </label>
                                <input class="form-control" type="number" id="total_area" name="total_area"
                                       placeholder="0.00" step="0.01"
                                       v-model="allotment.total_area">
                                <div class="validation" v-if="invalidFirstAba.total_area">
                                    {{ invalidFirstAba.total_area }}
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="initialPayment">{{ t('ALLOTMENT.TEMPLATE_PROPOSAL') }}: </label>
                                <select2 placeholder="template de proposta" :settings="{width: '100%'}"
                                         :options="templatesProposal" v-model="allotment.template_id"/>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12" v-if="hasPermissionShowBd()">
                            <div class="form-group">
                                <label for="idBd">{{
                                        t('ALLOTMENT.ID_BD')
                                    }}: </label>
                                <input class="form-control" type="number" id="idBd" name="idBd"
                                       @change="changedAllotmentBd"
                                       v-model="allotment.id_configuracao_bd" @wheel.prevent.stop="">
                            </div>
                        </div>
                        <div class="col-12 col-xl-4 col-md-4 col-lg-4 col-sm-4">
                            <div class="form-group">
                                <label :class="{'required' : allotment.id_configuracao_bd > 0}">{{
                                        t('ALLOTMENT.PERSON')
                                    }}: </label>
                                <select2 :placeholder="allotment.personName" v-if="allotment.personName && showPersonId"
                                         :settings="signatoryAllotmetsR2Owner" @select="setPersonAllotment"
                                         :disabled="!allotment.id_configuracao_bd > 0"
                                         v-model="allotment.person_id"/>
                                <select2 placeholder="Pessoa" v-else-if="showPersonId"
                                         :settings="signatoryAllotmetsR2Owner" @select="setPersonAllotment"
                                         :disabled="!allotment.id_configuracao_bd > 0"
                                         v-model="allotment.person_id"/>
                                <select2 placeholder="Pessoa" v-else
                                         :disabled="!allotment.id_configuracao_bd > 0"/>
                                <div class="validation" v-if="invalidFirstAba.person_id">
                                    {{ invalidFirstAba.person_id }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row gutters mb-1">
                        <div class="col-6">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="active"
                                       v-model="allotment.active">
                                <label class="custom-control-label" for="active">{{ t('ALLOTMENT.ACTIVE') }}</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="is_required_data_client"
                                       v-model="allotment.is_required_data_client">
                                <label class="custom-control-label" for="is_required_data_client">{{
                                        t('COMPANIES.MSG.REQUIRED_DATA_CLIENT_TO_CREATE_RESERVE')
                                    }}</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="is_required_email"
                                       v-model="allotment.is_required_email">
                                <label class="custom-control-label" for="is_required_email">{{
                                        t('ALLOTMENT.REQUIRED_DATA_EMAIL_TO_CREATE_RESERVE')
                                    }}</label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input"
                                       id="permissions_allotment_on_marketing"
                                       v-model="allotment.permissions_allotment_on_marketing">
                                <label class="custom-control-label" for="permissions_allotment_on_marketing">{{
                                        t('ALLOTMENT.MSG.ALLOW_MARKETING_OF_ALLOTMENT')
                                    }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" :class="{ active: activeTab == 1, show: activeTab == 1 }" id="localization"
                     role="tabpanel" aria-labelledby="localization-tab">
                    <address-generic :is-cep-required="true"
                                     :addressIndex="address" :is-invalid="invalidAddress"
                                     @update="updateAddress($event)"
                    />
                    <div class="row gutters">
                        <div class="col-12 mb-1">
                            <div class="dropdown-divider"></div>
                            <div class="text-center font-weight-bolder" style="color: #0e5e4e">
                                Coordenadas
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="latitude">{{ t('ALLOTMENT.LATITUDE') }}: </label>
                                <input type="text" id="latitude" v-model="allotment.latitude" name="latitude"
                                       class="form-control">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="longitude">{{ t('ALLOTMENT.LONGITUDE') }}: </label>
                                <input type="text" id="longitude" v-model="allotment.longitude" name="longitude"
                                       class="form-control">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="coordinate_type" class="d-flex flex-row">{{
                                        t('ALLOTMENT.COORDINATE_TYPE') }}:
                                </label>
                                <input type="text" id="coordinate_type" maxlength="255" placeholder="Ex: Sirgas 2000 - UTM / Zona: 24"
                                       v-model="allotment.coordinate_type" class="form-control">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" :class="{ active: activeTab == 2, show: activeTab == 2 }" id="financial"
                     role="tabpanel" aria-labelledby="financial-tab">
                    <div class="row gutters">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="monetaryCorrection">{{ t('ALLOTMENT.READJUSTMENTS') }}: </label>
                                <select2 :settings="{ width: '100%', placeholder: t('GENERAL.NONE') }"
                                         id="monetaryCorrection"
                                         name="monetaryCorrection" v-model="allotment.monetary_correction_indexes_id"
                                         :options="monetaryCorrections"/>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            <div class="form-group">
                                <label for="readjustPricePerMeter">{{
                                        t('ALLOTMENT.READJUST_PRICE_PER_METER')
                                    }}: </label>
                                <input class="form-control" type="text" id="readjustPricePerMeter"
                                       name="readjustPricePerMeter"
                                       v-model.lazy="allotment.readjust_price_per_meter" v-money3="configPricePerMeter"
                                       title="Campo para setar valor para reajustar o Lot quando o contrato é rescindido no Loteamento,
                                       valores superiores a zero serão aplicados.">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            <div class="form-group">
                                <label>{{
                                        t('ALLOTMENT.READJUSTMENT_MONTH')
                                    }}: </label>
                                <select2 :settings="{ width: '100%' }" placeholder="Selecione"
                                         v-model="allotment.readjustment_month"
                                         :options="monthsReadjustment"/>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            <div class="form-group">
                                <label for="date_decree_approval">{{
                                        t('ALLOTMENT.READJUSTMENT_CONTRACT_INSTALLMENT')
                                    }}: </label>
                                <select2 :settings="{ width: '100%' }" placeholder="Selecione"
                                         v-model="allotment.readjustment_contract_installment"
                                         :options="readjustment_contract_installment"/>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="brokerage">{{ t('ALLOTMENT.BROKERAGE_VALUE') }}: </label>
                                <div class="input-group">
                                    <input class="form-control" type="text" id="brokerage" name="brokerage"
                                           v-model.lazy="allotment.brokerage_value" v-money3="configBrokerage">
                                    <div class="input-group-prepend">
                                        <button class="btn btn-outline-secondary dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {{ t('GENERAL.TYPE') }}
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <a class="dropdown-item bg-hover-white"
                                               @click="resetConfigBrokerage(false)">{{ t('GENERAL.REAIS') }}</a>
                                            <a class="dropdown-item bg-hover-white"
                                               @click="resetConfigBrokerage(true)">{{ t('GENERAL.PERCENTAGE') }}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="simulator_config_id">Configuração de Simulador: </label>
                                <select2 :settings="{ width: '100%' }" id="simulator_config_id"
                                         name="simulator_config_id" v-model="allotment.simulator_config_id"
                                         :options="simulatorsConfigs"/>
                            </div>
                        </div>
                        <div class="col-12 mb-1" v-if="allotment.id_configuracao_bd > 0">
                            <div class="dropdown-divider"></div>
                            <div class="text-center font-weight-bolder" style="color: #0e5e4e">
                                Centro de Custo
                            </div>
                        </div>
                        <div class="col-12" v-if="allotment.id_configuracao_bd > 0">
                            <div class="form-group">
                                <div class="border  rounded  m-1">
                                    <div class="row pt-2 pr-3 pl-3 pb-3">
                                        <div class="col-12">
                                            <label for="date_decree_approval">{{
                                                    t('ALLOTMENT.CENTER_COUST')
                                                }}: </label>
                                            <select2 :settings="{width: '100%'}"
                                                     placeholder="Selecione"
                                                     :options="optionsCenterCoust"
                                                     @select="selectedCenterCoust"
                                                     v-model="centerCoust.cost_center_id"
                                            />
                                        </div>
                                        <div class="col-12 pl-4 pr-4 pt-1">
                                            <div class=" border-top2 mt-1 mb-2 row gutters"
                                                 v-for="(item, key) in this.allotment?.allotment_cost_center"
                                                 :key="key">
                                                <div class="col-11 mt-1">{{ item?.name }}</div>
                                                <div class="col-1 d-flex justify-content-end mt-1">
                                                    <i class="icon-trash align-self-center pointer"
                                                       style="font-size: 17px"
                                                       @click.stop.prevent="removeCenterCoust(key)"
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" :class="{ active: activeTab == 3, show: activeTab == 3 }" id="data-add"
                     role="tabpanel" aria-labelledby="financial-tab" v-if="activeTab === 3">
                    <div class="row gutters">
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            <div class="form-group">
                                <label for="registry_office">{{
                                        t('ALLOTMENT.REGISTRY_OFFICE')
                                    }}:
                                </label>
                                <input class="form-control" type="text" id="registry_office" name="registry_office"
                                       placeholder="Cartório"
                                       v-model="allotment.registry_office">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            <div class="form-group">
                                <label for="name">{{ t('ALLOTMENT.NAME_SYSTEM') }}: </label>
                                <input type="text" id="name_system"
                                       v-model="allotment.name_system"
                                       name="name_system" class="form-control"
                                       placeholder="Nome Sistema">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            <div class="form-group">
                                <label for="name">{{ t('ALLOTMENT.NAME_SITE') }}: </label>
                                <input type="text" id="name_site"
                                       v-model="allotment.name_site"
                                       name="name_site" class="form-control"
                                       placeholder="Nome Site">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="district">{{
                                        t('ALLOTMENT.DISTRICT')
                                    }}:
                                </label>
                                <input class="form-control" type="text" id="district" name="district"
                                       placeholder="Comarca"
                                       v-model="allotment.district">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="district">{{
                                        t('ALLOTMENT.ORIGINAL_BATCH')
                                    }}:
                                </label>
                                <input class="form-control" type="text" id="original_batch" name="original_batch"
                                       placeholder="Lote originário / Matricula Originária"
                                       v-model="allotment.original_batch">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <div class="form-group">
                                <label for="url_site">{{ t('ALLOTMENT.URL_SITE_SOCIAL_MIDIA') }}: </label>
                                <input data-cy="allotment_creat_modal_email" type="text" id="url_site"
                                       v-model="allotment.url_site" name="url_site" @keyup="inputUrl"
                                       class="form-control" placeholder="URL">
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            <div class="form-group">
                                <label>{{ t('ALLOTMENT.CHARACTERISTICS_ALLOTMENT') }}: </label>
                                <textarea class="form-control" v-model="allotment.description"
                                          id="description"
                                          rows="6"></textarea>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            <div class="form-group">
                                <label for="execution_works">{{ t('ALLOTMENT.EXECUTION_WORKS') }}: </label>
                                <textarea class="form-control" v-model="allotment.execution_works"
                                          id="execution_works"
                                          rows="6"></textarea>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                            <div class="form-group">
                                <label for="improvement">{{ t('ALLOTMENT.IMPROVEMENT') }}: </label>
                                <textarea class="form-control" v-model="allotment.improvement"
                                          id="improvement"
                                          rows="6"></textarea>
                            </div>
                        </div>
                        <div class="col-12 mb-1">
                            <div class="dropdown-divider"></div>
                            <div class="text-center font-weight-bolder" style="color: #0e5e4e">
                                Signatários
                            </div>
                        </div>
                        <div class="col-12" v-if="hasPermissionShowBd() && allotment.id_configuracao_bd > 0">
                            <ul class="nav nav-tabs" id="sigTab" role="tablist">
                                <div class="col-6">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" :class="{ active: signatoryActive === 1 }"
                                           @click="signatoryActive = 1" id="signatory-tab"
                                           data-toggle="tab" href="#signatory" role="tab" aria-controls="signatory"
                                           aria-disabled="true">{{ t('ALLOTMENT.SIGNATORY') }}</a>
                                    </li>
                                </div>
                                <div class="col-6">
                                    <li class="nav-item" role="presentation">
                                        <a class="nav-link" :class="{ active: signatoryActive === 2 }"
                                           @click.stop.prevent="signatoryActive = 2"
                                           id="signatory-disp-tab" data-toggle="tab" href="#signatory-disp" role="tab"
                                           aria-controls="localization"
                                           aria-disabled="true">{{ t('ALLOTMENT.SIGNER_DISP') }} </a>
                                    </li>
                                </div>
                            </ul>
                        </div>
                        <div class="col-12 row gutters m-2 p-2"
                             v-if="hasPermissionShowBd() && allotment.id_configuracao_bd > 0 && signatoryActive === 1">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label>{{ t('ALLOTMENT.SIGNER') }}: </label>
                                    <select2
                                            :placeholder="signatory_r2_allotmets?.personName? signatory_r2_allotmets.personName : 'Pessoa'"
                                            :settings="signatoryAllotmetsR2Owner"
                                            :disabled="!allotment.id_configuracao_bd > 0"
                                            v-model="signatory_r2_allotmets.pessoa_id"
                                            @select="setPersonSignatoryAllotmets"/>
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                <div class="form-group">
                                    <label>{{ t('ALLOTMENT.SIGN_AS') }}: </label>
                                    <select2 placeholder="Assina Como" :options="[{id:'seller',text: 'Vendedor(a)'},
                                {id:'witness', text: 'Testemunha'}]"
                                             :disabled="!allotment.id_configuracao_bd > 0"
                                             v-model="signatory_r2_allotmets.sign_as"/>
                                </div>
                            </div>
                            <div class="col-1 d-flex">
                                <button class="btn btn-primary align-self-center" title="Adicionar"
                                        :disabled="!allotment.id_configuracao_bd > 0"
                                        @click.stop.prevent="addSignatoryAllotmets"><i
                                        class="icon-plus"></i></button>
                            </div>
                            <div class="col-12 row gutters pt-2 border-top2 d-flex"
                                 v-for="(person, key) in this.allotment.signatory_r2_allotmets" :key="key">
                                <div class="col-6 ">
                                    <p>{{ person.personName }}</p>
                                </div>
                                <div class="col-5">
                                    <p>{{ person.sign_as === 'seller' ? 'Vendedor(a)' : 'Testemunha' }}</p>
                                </div>
                                <div class="col-1  align-self-center text-center">
                                    <i class="icon-trash pointer" @click="removeSignetoryAllotment(key)"
                                       style="font-size: 20px;"></i>
                                </div>
                            </div>
                        </div>
                        <label class="ml-2" v-if="!allotment.id_configuracao_bd > 0">{{
                                t('ALLOTMENT.SIGNER_DISP')
                            }}:</label>
                        <div class="col-12 row gutters m-2 p-2"
                             :class="!allotment.id_configuracao_bd > 0 ? 'border rounded' : ''"
                             style="margin-top: 0;"
                             v-if="!allotment.id_configuracao_bd > 0 || signatoryActive === 2">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                                <div class="form-group">
                                    <label>{{ t('ALLOTMENT.SIGNER') }}: </label>
                                    <select2 :placeholder="signer?.personName? signer.personName : 'Pessoa'"
                                             :settings="settingsOwner"
                                             v-model="signer.person_id" @select="setPersonSigner"/>
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
                                <div class="form-group">
                                    <label>{{ t('ALLOTMENT.SIGN_AS') }}: </label>
                                    <select2 placeholder="Assina Como" :options="[{id:'seller',text: 'Vendedor(a)'},
                                {id:'witness', text: 'Testemunha'}]"
                                             v-model="signer.sign_as"/>
                                </div>
                            </div>
                            <div class="col-1 d-flex">
                                <button class="btn btn-primary align-self-center" title="Adicionar"
                                        @click.stop.prevent="addSigner"><i
                                        class="icon-plus"></i></button>
                            </div>
                            <div class="col-12 row gutters pt-2 border-top2 d-flex"
                                 v-for="(person, key) in this.allotment.signatory" :key="key">
                                <div class="col-6 ">
                                    <p>{{ person.personName }}</p>
                                </div>
                                <div class="col-5">
                                    <p>{{ person.sign_as === 'seller' ? 'Vendedor(a)' : 'Testemunha' }}</p>
                                </div>
                                <div class="col-1  align-self-center text-center">
                                    <i class="icon-trash pointer" @click="removeSigner(key)"
                                       style="font-size: 20px;"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="justify-content: space-between">
                <div>
                    <h5 class="ml-3" style="color: red; font-size: .7rem;">{{ t('GENERAL.REQUIRED_FIELDS') }} *</h5>
                </div>
                <div>
                    <button data-cy="allotment_create_previous" type="button" v-if="activeTab != 0"
                            class="btn btn-secondary mr-2" @click="activeTab--">{{ t('ALLOTMENT.PREVIOUS') }}
                    </button>
                    <button data-cy="allotment_create_next" type="button" v-if="activeTab != 3"
                            class="btn btn-secondary mr-2" @click.stop.prevent="next">{{ t('ALLOTMENT.NEXT') }}
                    </button>
                    <button data-cy="allotment_create_save" type="submit"
                            class="btn btn-primary">{{ t('ACTIONS.SAVE') }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>

import {mapState} from 'vuex';
import {useI18n} from 'vue-i18n';
import jwt_decode from 'jwt-decode';
import City from '@/services/City.js';
import State from '@/services/State.js';
import {Money3Directive} from 'v-money3';
import {useToast} from 'vue-toastification';
import Select2 from 'vue3-select2-component';
import Allotments from '@/services/Allotments';
import Proposal from '../../services/Proposal';
import Validate from '@/components/validate.vue';
import SimulatorConfig from '../../services/SimulatorConfig';
import ConfigPublicArea from '../../services/ConfigPublicArea';
import MonetaryCorrection from '@/services/MonetaryCorrection.js';
import addressGeneric from '@/components/layouts/addressComponent.vue';


export default {
    name: 'createAllotment',

    mixins: [Validate],

    directives: {money3: Money3Directive},

    components: {
        Select2,
        addressGeneric,
    },

    props: {
        allotmentIndex: {
            type: Object,
            required: true,
        },
        isEdit: {
            type: Boolean,
            defaut: false,
        },
    },

    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast};
    },

    computed: {
        ...mapState({
            imageCropped: 'imageCropped',
        }),
        disablePublicArea() {
            if (!this.publicAreasConfigs) return true;

            return this.publicAreasConfigs.length === 0;
        },
    },

    data() {
        return {
            optionsCenterCoust: [],
            centerCoust: {},
            signatoryActive: 1,
            showPersonId: true,
            signer: {},
            signatory_r2_allotmets: {},
            invalidAddress: {},
            address: {},
            readjustment_contract_installment: [
                {
                    id: 'CONTRATOME',
                    text: 'Pelo Mês do Contrato',
                },
                {
                    id: 'PARCELASAL',
                    text: 'Aniversário da 1º Parcela do Saldo Devedor',
                },
                {
                    id: 'PARCELA',
                    text: 'Aniversário da 1º Parcela',
                },
            ],
            monthsReadjustment: [
                {
                    id: 1,
                    text: 'Janeiro',
                },
                {
                    id: 2,
                    text: 'Fevereiro',
                },
                {
                    id: 3,
                    text: 'Março',
                },
                {
                    id: 4,
                    text: 'Abril',
                },
                {
                    id: 5,
                    text: 'Maio',
                },
                {
                    id: 6,
                    text: 'Junho',
                },
                {
                    id: 7,
                    text: 'Julho',
                },
                {
                    id: 8,
                    text: 'Agosto',
                },
                {
                    id: 9,
                    text: 'Setembro',
                },
                {
                    id: 10,
                    text: 'Outubro',
                },
                {
                    id: 11,
                    text: 'Novembro',
                },
                {
                    id: 12,
                    text: 'Dezembro',
                },
            ],
            configTinyMce: {
                selector: 'textarea',
                license_key: 'gpl',
                contextmenu: false,
                plugins: 'print preview searchreplace autolink autosave save directionality visualchars fullscreen link charmap hr pagebreak toc advlist lists wordcount textpattern emoticons',
                menubar: 'edit format',
                toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen preview save print | media template link anchor codesample | ltr rtl',
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 300,
                content_css: 'default',
                language: 'pt_BR',
                images_upload_url: '',
                setup: function (editor) {
                    editor.on('Paste', function (e) {
                        var items = e.clipboardData.items;
                        for (var i = 0; i < items.length; i++) {
                            if (items[i].type.indexOf('image/') !== -1) {
                                e.preventDefault();
                                alert('Colar imagens não é permitido.');
                                return;
                            }
                        }
                    });
                    editor.on('drop', function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        alert('Arrastar e soltar de imagens não é permitido.');
                    });
                },
                file_picker_callback: function () {
                    return false;
                },
                images_upload_handler: function (blobInfo, success, failure) {
                    failure('Upload de imagem desabilitado');
                },
                image_advtab: false,
                image_title: false,
                automatic_uploads: false,
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            },
            cities: [],
            states: [],
            invalid: [],
            activeTab: 0,
            state_id: null,
            templatesProposal: {},
            allotment: {
                is_required_data_client: false,
            },
            msgError: null,
            msgReserveError: '',
            invalidFirstAba: {},
            invalidSecondAba: [],
            isCityInvalid: false,
            isNameInvalid: false,
            isStateInvalid: false,
            reserveDuration: null,
            simulatorsConfigs: [],
            publicAreasConfigs: [],
            isLoadingCities: false,
            monetaryCorrections: [],
            monetaryCorrection: null,
            isReserveDurationInvalid: false,
            config: {
                min: 0,
                suffix: '%',
                masked: true,
                decimal: '.',
                precision: 2,
                disabled: false,
                thousands: '',
                allowBlank: false,
                disableNegative: true,
            },
            settingsOwner: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return 'Por favor, insira ao menos 1 caracteres para pesquisar.';
                    },
                    noResults: function () {
                        return 'Nenhum resultado encontrado!';
                    },
                },
                minimumInputLength: 1,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/clientes/list`,
                    dataType: 'json',
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'company-id': localStorage.getItem('companyId'),
                    },
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            let results = data.map((i) => {
                                return {
                                    id: i.id,
                                    text: `${i.name} - ${i.taxpayer}`,
                                };
                            });
                            results.unshift({id: 0, text: 'Nenhum'});
                            return {
                                results: results,
                            };
                        } else {
                            return {results: ''};
                        }
                    },
                    cache: true,
                },
            },
            signatoryAllotmetsR2Owner: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return 'Por favor, insira ao menos 1 caracteres para pesquisar.';
                    },
                    noResults: function () {
                        return 'Nenhum resultado encontrado!';
                    },
                },
                minimumInputLength: 1,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/proprietarios`,
                    dataType: 'json',
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'company-id': localStorage.getItem('companyId'),
                        'id-bd': sessionStorage.getItem('idBD'),
                    },
                    data: params => {
                        return {
                            name: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: 'Nenhum'});
                            return {
                                results: data.map(({id, text: text}) => ({id, text})),
                            };
                        } else {
                            return {results: ''};
                        }
                    },
                    cache: true,
                },
                initSelection: function (element, callback) {
                    callback({id: 1, text: 'initSelection test'});
                },
            },
            configPricePerMeter: {
                min: 0,
                suffix: '',
                masked: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                thousands: '.',
                disabled: false,
                allowBlank: false,
                max: 100000000000,
                disableNegative: true,
                minimumNumberOfCharacters: 1,
            },
            configBrokerage: {
                min: 0,
                suffix: '%',
                masked: true,
                decimal: '.',
                precision: 2,
                disabled: false,
                thousands: '',
                allowBlank: false,
                disableNegative: true,
            },
            configFees: {
                min: 0,
                max: 100,
                masked: true,
                decimal: '.',
                precision: 4,
                disabled: false,
                allowBlank: false,
                disableNegative: true,
                minimumNumberOfCharacters: 0,
            },
            configDuration: {
                min: 0,
                max: 999,
                masked: true,
                decimal: ':',
                precision: 2,
                disabled: false,
                allowBlank: false,
                disableNegative: true,
                minimumNumberOfCharacters: 0,
            },
            configEditor: {
                height: '200',
            },
        };
    },
    mounted() {
        this.$store.commit('changeLoading', true);
        this.$store.commit('setImageCropped', null);

        this.startModalInfs();
        this.indexMonetaryCorrection();
        this.indexState();
        this.listTemplateProposals();
        if (this.allotment) {
            this.setConfigBrokerage(this.allotment.brokerage_is_percentage);
        }
        this.changedAllotmentBd();
        this.getOptionsCenterCoust()
    },
    watch: {
        allotmentIndex: function () {
            this.$store.commit('setImageCropped', null);
            this.updateModalInfs();
            this.setConfigBrokerage(this.allotmentIndex.brokerage_is_percentage);
        },
    },
    methods: {
        setCoordinateType({id}) {
            if (id === 'selecione') {
                this.allotment.coordinate_type = null
            }
        },
        getOptionsCenterCoust() {
            if (sessionStorage.getItem('idBD')) {
                Allotments.listCenterCoust(sessionStorage.getItem('idBD')).then(resp => {
                    this.optionsCenterCoust = [];
                    if (this.allotment?.allotment_cost_center?.length) {
                        resp.data.forEach(c => {
                            let has = this.allotment?.allotment_cost_center.find(i => parseInt(i.cost_center_id) === parseInt(c.id));
                            if (!has?.cost_center_id) {
                                this.optionsCenterCoust.push(c);
                            }
                        })
                    } else {
                        this.optionsCenterCoust = resp.data;
                    }
                })
            } else {
                this.optionsCenterCoust = [];
            }
        },
        selectedCenterCoust({id, text}) {
            if (text !== 'Nenhum') {
                if (!this.allotment.allotment_cost_center) this.allotment.allotment_cost_center = [];
                let coust = this.allotment.allotment_cost_center.find(i => parseInt(i.cost_center_id) === parseInt(id));
                if (coust?.cost_center_id) {
                    this.centerCoust = {};
                    return;
                } else {
                    this.centerCoust.cost_center_id = id;
                    this.centerCoust.name = text;
                    this.allotment.allotment_cost_center.push(this.centerCoust);
                    this.centerCoust = {};
                    this.optionsCenterCoust = this.optionsCenterCoust.filter((i) => parseInt(i.id) !== parseInt(id))
                    return;
                }
            } else {
                this.centerCoust.cost_center_id = null;
                this.centerCoust.name = null;
            }
        },
        addCenterCoust() {
            if (this.centerCoust.cost_center_id) {
                if (!this.allotment.allotment_cost_center) this.allotment.allotment_cost_center = [];
                let coust = this.allotment.allotment_cost_center.find(i => i.cost_center_id === this.centerCoust.cost_center_id);
                if (!coust?.cost_center_id) {
                    this.allotment.allotment_cost_center.push(this.centerCoust);
                }
                this.centerCoust = {};
            }
        },
        removeCenterCoust(key) {
            const coust = this.allotment.allotment_cost_center.find((i, k) => k === key);
            this.allotment.allotment_cost_center = this.allotment.allotment_cost_center.filter((i, k) => k !== key);
            this.optionsCenterCoust.push({id: coust.cost_center_id, text: coust.name});
        },
        changedAllotmentBd() {
            this.showPersonId = false;
            setTimeout(() => {
                if (this.allotment?.id_configuracao_bd > 0) {
                    sessionStorage.setItem('idBD', this.allotment.id_configuracao_bd);
                    this.reloadSelectPerson();
                } else {
                    sessionStorage.removeItem('idBD');
                    this.reloadSelectPerson();
                }
                this.showPersonId = true;
            }, 100);
        },
        setPersonAllotment({id, text}) {
            this.allotment.person_id = id;
            this.allotment.personName = text;
        },
        removeSigner(key) {
            this.allotment.signatory = this.allotment.signatory.filter((i, k) => k !== key);
        },
        removeSignetoryAllotment(key) {
            this.allotment.signatory_r2_allotmets = this.allotment.signatory_r2_allotmets.filter((i, k) => k !== key);
        },
        setPersonSigner({id, text}) {
            this.signer.personName = text;
            this.signer.person_id = id;
        },
        setPersonSignatoryAllotmets({id, text}) {
            this.signatory_r2_allotmets.personName = text;
            this.signatory_r2_allotmets.pessoa_id = id;
        },
        addSigner() {
            if (!this.signer.person_id || !this.signer.sign_as) {
                this.toast.error('Necessario informar um signatário e como ele assina');
                return;
            }
            if (!this.allotment.signatory) {
                this.allotment.signatory = [];
            }
            this.allotment.signatory.push(this.signer);
            this.signer = {};
        },
        addSignatoryAllotmets() {
            if (!this.signatory_r2_allotmets.pessoa_id || !this.signatory_r2_allotmets.sign_as) {
                this.toast.error('Necessario informar um signatário e como ele assina');
                return;
            }
            if (!this.allotment.signatory_r2_allotmets) {
                this.allotment.signatory_r2_allotmets = [];
            }
            this.allotment.signatory_r2_allotmets.push(this.signatory_r2_allotmets);
            this.signatory_r2_allotmets = {};
        },
        updateAddress(address) {
            this.allotment.cep = address?.cep;
            this.allotment.street = address?.street;
            this.allotment.number = address?.number;
            this.allotment.neighborhood = address?.neighborhood;
            this.allotment.complement = address?.complement;
            this.allotment.city_id = address?.city_id;
        },
        changeSignatory(val) {
            this.allotment.signatory = val;
        },
        hasPermissionShowBd() {
            const token = jwt_decode(localStorage.getItem('access_token'));
            return token.email.includes('@r2soft.com.br');
        },

        listTemplateProposals() {
            Proposal.listTemplate().then(resp => this.templatesProposal = resp.data);
        },
        formatterValue(value) {
            value = value.replace('R$ ', '');
            value = value.replaceAll('.', '');
            value = value.replaceAll(',', '.');
            value = parseFloat(value).toFixed(2);
            return value;
        },
        validateAddress() {
            let invalid = false;
            if (!this.allotment.cep) {
                this.invalidAddress.cep = 'Campo obrigatório';
                invalid = true;
            } else {
                this.invalidAddress.cep = null;
            }
            if (!this.allotment.street) {
                this.invalidAddress.street = 'Campo obrigatório';
                invalid = true;
            } else {
                this.invalidAddress.street = null;
            }
            if (!this.allotment.number) {
                this.invalidAddress.number = 'Campo obrigatório';
                invalid = true;
            } else {
                this.invalidAddress.number = null;
            }
            if (!this.allotment.number) {
                this.invalidAddress.number = 'Campo obrigatório';
                invalid = true;
            } else {
                this.invalidAddress.number = null;
            }
            if (!this.allotment.neighborhood) {
                this.invalidAddress.neighborhood = 'Campo obrigatório';
                invalid = true;
            } else {
                this.invalidAddress.neighborhood = null;
            }
            return invalid;
        },
        create(nextCreate = false) {
            this.startDependeciesAllotment();
            if (this.validateFirstAba()) {
                if (this.validateAddress()) {
                    this.toast.error('Bloco de Endereço é obrigatório');
                    return;
                }
                if (!this.allotment.administrate_tax) {
                    this.allotment.administrate_tax = 0.00;
                }
                if (this.centerCoust.cost_center_id && this.centerCoust.name && this.centerCoust.name !== 'Nenhum') {
                    if (!this.allotment.allotment_cost_center) this.allotment.allotment_cost_center = [];
                    let coust = this.allotment.allotment_cost_center.find(i => i.cost_center_id === this.centerCoust.cost_center_id);
                    if (!coust?.cost_center_id) {
                        this.allotment.allotment_cost_center.push(this.centerCoust);
                    }
                    this.centerCoust = {};
                } else {
                    this.centerCoust = {};
                }
                if (this.signer.person_id && this.signer.sign_as) {
                    if (!this.allotment.signatory) {
                        this.allotment.signatory = [];
                    }
                    this.allotment.signatory.push(this.signer);
                } else {
                    this.signer = {};
                }

                if (this.signatory_r2_allotmets.pessoa_id && this.signatory_r2_allotmets.sign_as) {
                    if (!this.allotment.signatory_r2_allotmets) {
                        this.allotment.signatory_r2_allotmets = [];
                    }
                    this.allotment.signatory_r2_allotmets.push(this.signatory_r2_allotmets);
                } else {
                    this.signatory_r2_allotmets = {};
                }

                this.allotment.image = this.imageCropped ? this.imageCropped : this.allotment.image;
                this.allotment.monetary_correction_indexes_id = this.allotment.monetary_correction_indexes_id == 0 ? null : this.allotment.monetary_correction_indexes_id;
                this.allotment.readjust_price_per_meter = this.formatterValue(this.allotment.readjust_price_per_meter);
                if (this.allotment?.signatory?.length) {
                    this.allotment.signatoryBackup = this.allotment.signatory;
                    this.allotment.signatory = this.allotment.signatory.map((i) => {
                        return {
                            person_id: i.person_id,
                            sign_as: i.sign_as,
                        };
                    });
                }
                if (this.allotment?.signatory_r2_allotmets?.length) {
                    this.allotment.signatoryAllotmentBackup = this.allotment.signatory_r2_allotmets;
                    this.allotment.signatory_r2_allotmets = this.allotment.signatory_r2_allotmets.map((i) => {
                        return {
                            pessoa_id: i.pessoa_id,
                            sign_as: i.sign_as,
                        };
                    });
                }
                this.allotment.id ? this.upsert(Allotments.update(this.allotment), 'UPDATE_SUCCESS', nextCreate)
                        : this.upsert(Allotments.create(this.allotment), 'REGISTER_SUCCESS', nextCreate);
            }
        },

        upsert(promise, word, nextCreate = false) {
            this.$store.commit('changeLoading', true);
            promise.then((resp) => {
                if (!nextCreate) {
                    document.getElementById('closeX').click();
                    this.$store.commit('setImageCropped', null);
                    this.toast.success(this.t(`GENERAL.MSG.${word}`));
                    this.$emit('created');
                } else {
                    this.$store.commit('changeLoading', false);
                    this.allotment.id = resp.data.id;
                    this.activeTab = nextCreate;
                }
            }).catch(err => {
                this.errorMsg(err);
                this.allotment.signatory = this.allotment.signatoryBackup;
                this.allotment.signatory_r2_allotmets = this.allotment.signatoryAllotmentBackup;
            }).finally(() => this.$store.commit('changeLoading', false));
        },

        reloadSelectPerson() {
            this.signatoryAllotmetsR2Owner = {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return 'Por favor, insira ao menos 1 caracteres para pesquisar.';
                    },
                    noResults: function () {
                        return 'Nenhum resultado encontrado!';
                    },
                },
                minimumInputLength: 1,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/proprietarios`,
                    dataType: 'json',
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'company-id': localStorage.getItem('companyId'),
                        'id-bd': sessionStorage.getItem('idBD'),
                    },
                    data: params => {
                        return {
                            name: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data.unshift({id: 0, text: 'Nenhum'});
                            return {
                                results: data.map(({id, text: text}) => ({id, text})),
                            };
                        } else {
                            return {results: ''};
                        }
                    },
                    cache: true,
                },
                initSelection: function (element, callback) {
                    callback({id: 1, text: 'initSelection test'});
                },
            };
            this.getOptionsCenterCoust();
        },

        emailChange(description) {
            this.allotment.description = description;
        },

        startModalInfs() {
            let data = this.allotmentIndex;
            if (this.allotmentIndex.signatory) {
                data.signatory = JSON.parse(this.allotmentIndex.signatory);
            }
            if (this.allotmentIndex.signatory_r2_allotmets) {
                data.signatory_r2_allotmets = JSON.parse(this.allotmentIndex.signatory_r2_allotmets);
            }
            this.address.cep = data?.cep;
            this.address.street = data?.street;
            this.address.number = data?.number;
            this.address.complement = data?.complement;
            this.address.neighborhood = data?.neighborhood;
            this.address.is_international = data?.is_international;
            this.address.cityName = data?.cityName;
            this.address.stateName = data?.stateName;
            this.allotment = data;
            this.setConfigMoney(this.allotmentIndex.initial_payment_required_is_percentage);
            this.allotment.description = this.allotmentIndex.description ? this.allotmentIndex.description : '';
            this.allotment.is_required_data_client = this.allotmentIndex.is_required_data_client ? this.allotmentIndex.is_required_data_client : false;
            this.allotment.initial_payment_required = this.validateMoney3(this.allotment.initial_payment_required);
            this.allotment.brokerage_value = this.validateMoney3(this.allotment.brokerage_value);
            this.allotment.simulator_config_id = this.allotment.simulator_config_id ? this.allotment.simulator_config_id.toString() : '1';
            this.allotment.public_area_config_id = this.allotment.public_area_config_id ? this.allotment.public_area_config_id.toString() : '1';
            this.allotment.city_id = this.allotmentIndex.cities && this.allotmentIndex.cities.id ? this.allotmentIndex.cities.id : null;
            this.state_id = this.allotmentIndex.cities && this.allotmentIndex.cities.states.id ? this.allotmentIndex.cities.states.id : null;

            if (this.allotmentIndex.cities) {
                this.indexCity(this.state_id);
            }
        },

        listSimulatorsConfigurations() {
            SimulatorConfig.list()
                    .then(res => this.simulatorsConfigs = res.data)
                    .catch(err => this.errorMsg(err));
        },

        listPublicAreaConfigurations() {
            if (!this.publicAreasConfigs || this.publicAreasConfigs.length === 0) {
                ConfigPublicArea.list()
                        .then(res => this.publicAreasConfigs = res.data)
                        .catch(err => this.errorMsg(err));
            }
        },

        updateModalInfs() {
            this.signatory_r2_allotmets = {};
            this.signer = {};
            this.invalidFirstAba = {};
            this.activeTab = 0;
            this.isNameInvalid = false;
            this.isCityInvalid = false;
            this.isStateInvalid = false;
            let data = this.allotmentIndex;
            if (this.allotmentIndex.signatory) {
                data.signatory = JSON.parse(this.allotmentIndex.signatory);
            }
            if (this.allotmentIndex.signatory_r2_allotmets) {
                data.signatory_r2_allotmets = JSON.parse(this.allotmentIndex.signatory_r2_allotmets);
            }
            this.address.cep = data?.cep;
            this.address.street = data?.street;
            this.address.number = data?.number;
            this.address.complement = data?.complement;
            this.address.neighborhood = data?.neighborhood;
            this.address.is_international = data?.is_international;
            this.address.cityName = data?.cityName;
            this.address.stateName = data?.stateName;
            this.allotment = data;
            this.isReserveDurationInvalid = false;
            document.getElementById('name').classList.remove('is-valid');
            document.getElementById('name').classList.remove('is-invalid');
            document.getElementById('reserveDuration').classList.remove('is-valid');
            document.getElementById('reserveDuration').classList.remove('is-invalid');
            this.setConfigMoney(this.allotmentIndex.initial_payment_required_is_percentage);
            this.allotment.description = this.allotmentIndex.description ? this.allotmentIndex.description : '';
            this.allotment.initial_payment_required = this.validateMoney3(this.allotment.initial_payment_required);
            this.allotment.brokerage_value = this.validateMoney3(this.allotment.brokerage_value);
            this.state_id = this.allotmentIndex.cities && this.allotmentIndex.cities.states.id ? this.allotmentIndex.cities.states.id : null;
            this.allotment.city_id = this.allotmentIndex.cities && this.allotmentIndex.cities.id ? this.allotmentIndex.cities.id : null;

            if (this.allotmentIndex.cities) {
                this.indexCity(this.state_id);
            }
            this.changedAllotmentBd();
        },

        startDependeciesAllotment() {
            var initialPaymentFinal;
            if (this.allotment.initial_payment_required_is_percentage) {
                initialPaymentFinal = this.allotment.initial_payment_required.toString().replaceAll('%', '');
                initialPaymentFinal = initialPaymentFinal.replaceAll(',', '.');
            } else {
                initialPaymentFinal = this.allotment.initial_payment_required.replaceAll('R$ ', '');
                initialPaymentFinal = initialPaymentFinal.replaceAll('.', '');
                initialPaymentFinal = initialPaymentFinal.replaceAll(',', '.');
            }
            var brokerageValueFinal;
            if (this.allotment.brokerage_is_percentage) {
                brokerageValueFinal = this.allotment.brokerage_value.replaceAll('%', '');
                brokerageValueFinal = brokerageValueFinal.replaceAll(',', '.');
            } else {
                brokerageValueFinal = this.allotment.brokerage_value.replaceAll('R$ ', '');
                brokerageValueFinal = brokerageValueFinal.replaceAll('.', '');
                brokerageValueFinal = brokerageValueFinal.replaceAll(',', '.');
            }
            this.allotment.cities = null;
            this.allotment.monetary_correction_indexes = null;
            this.allotment.initial_payment_required = initialPaymentFinal;
            this.allotment.brokerage_value = brokerageValueFinal;
        },

        next() {
            if (this.activeTab == 0) {
                if (this.validateFirstAba()) {
                    this.activeTab++;
                }
            } else {
                this.activeTab++;
            }
        },

        validateSecondAbaClick() {
            if (this.validateFirstAba()) {
                this.activeTab = 1;
            }
        },

        validateLastAbaClick() {
            if (this.validateFirstAba()) {
                this.activeTab = 2;
            }
        },
        validateThirthAbaClick() {
            if (this.validateFirstAba()) {
                this.activeTab = 3;
            }
        },

        validateFirstAba() {
            this.invalid = [];

            this.reserveDurationValidate();
            this.nameValidate(this.allotment.name);
            this.validateGeneric(this.allotment.max_number_reserve_per_realtor, 'maxReserve');
            if (!this.allotment.date_launch) {
                this.invalidFirstAba.date_launch = 'Campo obrigatório';
                this.invalid.push({1: 'invalid'});
            } else {
                this.invalidFirstAba.date_launch = null;
            }
            if (this.allotment.id_configuracao_bd > 0 && !this.allotment.person_id) {
                this.invalidFirstAba.person_id = 'Campo obrigatório';
                this.invalid.push({1: 'invalid'});
            } else {
                this.invalidFirstAba.person_id = null;
            }
            if (!this.allotment.date_decree_approval) {
                this.invalidFirstAba.date_decree_approval = 'Campo obrigatório';
                this.invalid.push({1: 'invalid'});
            } else {
                this.invalidFirstAba.date_decree_approval = null;
            }
            if (!this.allotment.decree_approval) {
                this.invalidFirstAba.decree_approval = 'Campo obrigatório';
                this.invalid.push({1: 'invalid'});
            } else {
                this.invalidFirstAba.decree_approval = null;
            }
            if (!this.allotment.total_area) {
                this.invalidFirstAba.total_area = 'Campo obrigatório';
                this.invalid.push({1: 'invalid'});
            } else {
                this.invalidFirstAba.total_area = null;
            }
            if (this.invalid.length && this.invalid.length > 0) {
                return false;
            }
            return true;
        },

        cityValidate() {
            if (!this.state_id) {
                this.isStateInvalid = true;
            } else
                this.isStateInvalid = false;


            if (!this.allotment.city_id) {
                this.isCityInvalid = true;
                this.invalidSecondAba.push(this.isCityInvalid);

            } else {
                this.isCityInvalid = false;
            }
        },

        reserveDurationValidate() {

            if (!this.allotment.reserve_duration_max || this.allotment.reserve_duration_max == '0:00') {
                this.isReserveDurationInvalid = true;
                this.msgReserveError = this.t('GENERAL.MSG.REQUIRED_FIELD');
                this.invalid.push(this.isReserveDurationInvalid);
                document.getElementById('reserveDuration').classList.add('is-invalid');

            } else {
                var reserve = this.allotment.reserve_duration_max.split(':');
                if (reserve[1] > 59) {
                    this.isReserveDurationInvalid = true;
                    this.msgReserveError = 'Quantidade de Minutos Invalida';
                    this.invalid.push(this.isReserveDurationInvalid);
                    document.getElementById('reserveDuration').classList.add('is-invalid');

                } else {
                    this.isReserveDurationInvalid = false;
                    document.getElementById('reserveDuration').classList.add('is-valid');
                }
            }
        },

        inputReserve() {
            this.isReserveDurationInvalid = false;
            document.getElementById('reserveDuration').classList.remove('is-valid');
            document.getElementById('reserveDuration').classList.remove('is-invalid');
            this.reserveDurationValidate();
        },

        resetConfigMoney(type) {
            this.allotment.initial_payment_required = 0;
            this.setConfigMoney(type);
        },

        resetConfigBrokerage(type) {
            this.allotment.brokerage_value = 0;
            this.setConfigBrokerage(type);
        },

        setConfigMoney(type) {
            this.allotment.initial_payment_required_is_percentage = type;
            if (type) {
                this.config.max = 100;
                this.config.prefix = '';
                this.config.decimal = '.';
                this.config.thousands = '';
                this.config.suffix = '%';
                this.config.disabled = false;
                this.config.minimumNumberOfCharacters = 0;
            } else {
                this.config.suffix = '';
                this.config.decimal = ',',
                        this.config.thousands = '.',
                        this.config.prefix = 'R$ ';
                this.config.disabled = false;
                this.config.max = 100000000000;
                this.config.minimumNumberOfCharacters = 1;
            }
        },

        setConfigBrokerage(type) {
            if (type === undefined) {
                type = true;
            }
            this.allotment.brokerage_is_percentage = type;
            if (type) {
                this.configBrokerage.max = 100;
                this.configBrokerage.prefix = '';
                this.configBrokerage.decimal = '.';
                this.configBrokerage.thousands = '';
                this.configBrokerage.suffix = '%';
                this.configBrokerage.disabled = false;
                this.configBrokerage.minimumNumberOfCharacters = 0;
            } else {
                this.configBrokerage.suffix = '';
                this.configBrokerage.decimal = ',';
                this.configBrokerage.thousands = '.';
                this.configBrokerage.prefix = 'R$ ';
                this.configBrokerage.disabled = false;
                this.configBrokerage.max = 100000000000;
                this.configBrokerage.minimumNumberOfCharacters = 1;
            }
        },

        async indexState() {
            await State.list().then(resp => {
                this.states = resp.data;
            });
            if (this.allotment.state_id) {
                this.state_id = this.allotment.state_id;
                this.indexCity(this.state_id);
            }
            this.$store.commit('changeLoading', false);
        },

        indexMonetaryCorrection() {
            MonetaryCorrection.list().then(resp => {
                this.monetaryCorrections = resp.data;
                this.monetaryCorrections.unshift({id: 0, text: this.t('GENERAL.NONE')});
            });
        },

        async indexCity(state_id) {
            this.isLoadingCities = true;
            await City.indexWithState(state_id).then(resp => {
                this.cities = resp.data;
                this.isLoadingCities = false;
            });
        },

        onSelectCity({id}) {
            this.allotment.city_id = id;
            document.getElementById('city').classList.remove('is-valid');
            document.getElementById('city').classList.remove('is-invalid');
            this.cityValidate();
        },

        uploadImage() {
            const file = document.querySelector('input[type=file]').files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                this.isCropping = true;
                this.$emit('logo', reader.result);
            };

            reader.readAsDataURL(file);
        },
    },
};
</script>
<style lang="css" scoped>
.right-content {
    justify-content: flex-end;
}

.wizard > .content > .body {
    position: relative !important;
}

.custom-control.custom-switch .custom-control-label::after {
    top: 4px !important;
}

.user-logo {
    max-width: 110px !important;
    max-height: 110px !important;
    margin-bottom: 1.2rem !important;
}

.logo-size {
    width: 110px !important;
    height: 110px !important;
}

.cropper-size {
    max-width: 350px !important;
    max-height: 275px !important;
}

.logo .status {
    top: 82px;
    right: 2px;
    width: 30px;
    height: 30px;
    font-size: 18px;
    position: absolute;
    border-radius: 15px;
    border: 4px solid #f0f0f0;
}

.logo {
    position: relative;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;

}

input[type=number] {
    -moz-appearance: textfield;
}

.camera2 {
    right: 1;
    bottom: 0;
    outline: 0;
    cursor: pointer;
    font-size: 23px;
    position: absolute;
    border-radius: 15px;
    background-color: #f0f0f0;
}
</style>
